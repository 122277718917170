<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SkrCheckbox from '@/components/SkrCheckbox.vue'

defineProps<{
  loading: boolean
}>()

const emit = defineEmits<{
  (event: 'close' | 'action'): void
}>()

const { t } = useI18n()

const isConfirmed = ref(false)
</script>

<template>
  <base-dialog
    data-cy="delete_account_dialog"
    max-width="640"
    :title="t('settings.delete_account.dialog.title')"
    :disabled="!isConfirmed || loading"
    :loading="loading"
    :action-text="t('settings.delete_account.dialog.cta.label')"
    action-color="error"
    :cancel-text="t('global.cancel')"
    @action="emit('action')"
    @close="emit('close')"
  >
    <v-row>
      <v-col cols="12" sm="6">
        <p class="mb-4">
          {{ t('settings.delete_account.dialog.text') }}
        </p>
        <skr-checkbox
          v-model="isConfirmed"
          :label="t('settings.delete_account.dialog.consent')"
          color="error"
          data-cy="confirm_delete_account_checkbox"
        />
      </v-col>
      <v-col v-if="$vuetify.display.smAndUp" sm="6">
        <responsive-image source="delete-skribble" :width="565" />
      </v-col>
    </v-row>
  </base-dialog>
</template>
